type EventEmitterOptions = { type: string, eventOptions?: CustomEventInit, callback?: (this: HTMLElement) => unknown }

/**
 *
 * Shared event emitter factory function for reusing/caching event settings so that events bubble by default,
 * can accept a callback for preventable events, but can be configured and overridden
 *
 */
export function eventEmitter(target: HTMLElement) {
  return (options: EventEmitterOptions) => {
    const { type, eventOptions, callback } = options;

    const event = new CustomEvent(type, {
      bubbles: true,
      cancelable: callback instanceof Function ? true : false,
      composed: true,
      detail: {},
      ...eventOptions
    });

    target.dispatchEvent(event);

    // if a callback function is specified, run it unless the event has been canceled
    if (callback instanceof Function) {
      if (!event.defaultPrevented) {
        callback.call(target);
      }
    }
  }
}

